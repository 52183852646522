import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// Use environment variables for sensitive information
const firebaseConfig = {
  apiKey: "AIzaSyB-g3o_kFR0U1pUtxoCrCfcMeJKlhDOVRk",
  authDomain: "splode-events.firebaseapp.com",
  projectId: "splode-events",
  storageBucket: "splode-events.appspot.com",
  messagingSenderId: "231947875727",
  appId: "1:231947875727:web:05c9068d3c17c1ee13c7fa",
  measurementId: "G-QNCL6V5Y5H"
};

// Initialize Firebase safely with error handling
let app, analytics, auth;
try {
  app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
  auth = getAuth(app);
  console.log("Firebase initialized successfully.");
} catch (error) {
  console.error("Firebase initialization error:", error);
  // Optionally reload the page if initialization fails
  setTimeout(() => {
    window.location.reload();
  }, 5000); // Retry after 5 seconds
}

export { app, analytics, auth };
// Export the authentication object along with the other exports
export default firebaseConfig;
