import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateTimeChange = ({ currentDateTime,directPurchase , updateData, onDateTimeChange, isOpen, onClose }) => {
  // Calculate tomorrow's date
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(9, 0, 0, 0); // Default time to 9:00 AM

  // Use currentDateTime if valid, otherwise fallback to tomorrow
  const initialDateTime = currentDateTime instanceof Date && !isNaN(currentDateTime) ? currentDateTime : tomorrow;

  const [newDate, setNewDate] = useState(initialDateTime);
  const [newTime, setNewTime] = useState(new Date(initialDateTime)); // Use a separate state for time

  useEffect(() => {
    // Sync state with currentDateTime whenever it changes
    if (currentDateTime instanceof Date && !isNaN(currentDateTime)) {
      setNewDate(currentDateTime);
      setNewTime(currentDateTime);
    }
  }, [currentDateTime]);

  const handleDateChange = (date) => {
    setNewDate(date);
    setNewTime(new Date(date)); // Sync the time state when date changes
  };

  const handleTimeChange = (event) => {
    const [hours, minutes] = event.target.value.split(":").map(Number);
    const updatedTime = new Date(newDate);
    updatedTime.setHours(hours, minutes, 0, 0);
    setNewTime(updatedTime);
  };

  const handleSubmit = () => {
    const newDateTime = new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      newTime.getHours(),
      newTime.getMinutes()
    );
    onDateTimeChange(newDateTime); // Send the new datetime to the parent
    if(directPurchase){
    updateData(new Date(newDateTime))
  }
    onClose();
  };

  // Define specific time slots for selection
  const timeSlots = [
    { label: "9:00 AM", value: "09:00" },
    { label: "12:00 PM", value: "12:00" },
    { label: "3:00 PM", value: "15:00" },
    { label: "6:00 PM", value: "18:00" },
    { label: "9:00 PM", value: "21:00" },
  ];

  return (
    <>
      {isOpen && (
        <div
          className="fixed z-40 inset-0 bg-gray-800 opacity-50"
          onClick={onClose}
        ></div>
      )}
      <div
        className={`fixed container mx-auto w-full max-w-screen-sm z-50 bottom-0 transform transition-transform duration-300 ${
          isOpen ? "translate-y-0" : "translate-y-full"
        } ease-in-out bg-white p-4`}
      >
        <div className="mb-4">
          <div className="py-3 font-semibold text-sm">Select New Date & Time</div>

          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700">Date</label>
            <DatePicker
              selected={newDate}
              onChange={handleDateChange}
              dateFormat="yyyy-MM-dd"
              minDate={tomorrow} // Prevent selecting today or earlier dates
              className="border border-gray-300 px-2 py-1 rounded w-full"
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700">Time</label>
            <select
              value={`${newTime.getHours().toString().padStart(2, "0")}:${newTime
                .getMinutes()
                .toString()
                .padStart(2, "0")}`}
              onChange={handleTimeChange}
              className="border border-gray-300 px-2 py-1 rounded w-full"
            >
              {timeSlots.map((slot) => (
                <option key={slot.value} value={slot.value}>
                  {slot.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button
          onClick={handleSubmit}
          className="bg-green-400 text-white px-3 py-1 rounded cursor-pointer"
        >
          Done
        </button>
      </div>
    </>
  );
};

export default DateTimeChange;
