const SidePanel = () => {

  return (
    <div className="fixed top-0 left-0 bg-transparent z-40 w-56 h-screen overflow-y-auto flex flex-col">
      <nav className="bg-gray-800 text-white flex-grow">
        <ul className="py-4">
          <li className="pl-6 py-3 text-lg font-bold">
            <a href="/admin/home">Dashboard</a>
          </li>
          <li className="pl-6 py-3">
            <a href="/admin/home">Home</a>
          </li>
          <li className="pl-6 py-3">
            <a href="/admin/products">Products</a>
          </li>
          <li className="pl-6 py-3">
            <a href="/admin/categories">Categories</a>
          </li>
          <li className="pl-6 py-3">
            <a href="/admin/orders">Orders</a>
          </li>
          <li className="pl-6 py-3">
            <a href="/admin/Slider">Slider</a>
          </li>
          <li className="pl-6 py-3">
            <a href="/admin/zipcode">Zip Codes</a>
          </li>
          <li className="pl-6 py-3">
            <a href="/admin/leads">Leads</a>
          </li>
        </ul>
      </nav>

    
      <div className="flex-shrink-0 mt-auto bg-gray-800 px-6 py-3">
           <p className="text-sm text-white">&copy; 2023 Splode Events</p>
        </div>
    </div>
  );
};

export default SidePanel;
