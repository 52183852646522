import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import useFirebaseCleanup from "./firebase/cleanUp"; // Import the cleanup hook

import { trackActivity } from "./Components/TrackUser";
import ProductPage from "./ProductPage";
import App from "./App";
import ProductList from "./ProductList";
import ShoppingBag from "./Components/ShopingBag";
import AdminLogin from "./admin/AdminLogin";
import AdminHome from "./admin/Home";
import AdminProducts from "./admin/Products";
import AdminCategories from "./admin/Categories";
import Orders from "./admin/Orders";
import AdminSliderPage from "./admin/Slider";
import ZipcodePage from "./admin/ZipCode";
import AddAddress from "./Components/AddAddress";
import AddNewAddress from "./Components/AddNewAddress";
import Paymentpage from "./PaymentMethod";
import OrderPlaced from "./Components/OrderPlaced";
import OrderConfirmation from "./Components/OrderConfirmation";
import Order from "./Components/MyOrders";
import ContactUs from "./ContactUs";
import FaqPage from "./FAQ";
import TermsAndConditionsPage from "./TermsCondition";
import BalloonDecorationPage from "./Components/BalloonDecorationPage";
import FloatingContact from "./Components/FloatingContact";
import AdminLeads from "./admin/LeadsAdmin";


const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    trackActivity(location.pathname, "Page Viewed");
  }, [location]);

  return null; // This component doesn't render anything
};

const Router = () => {
  // Apply the cleanup logic
  useFirebaseCleanup();

  const MobileContainer = ({ children }) => (
    <div className="bg-black min-h-screen flex justify-center">
      <div className="container mx-auto w-full max-w-screen-sm">{children}</div>
    </div>
  );

  return (
    <BrowserRouter>
      <PageTracker />
      <Routes>
        <Route
          path="/"
          element={
            <MobileContainer>
              <App />
            </MobileContainer>
          }
        />
        <Route
          path="/product/:productId"
          element={
            <MobileContainer>
              <ProductPage />
            </MobileContainer>
          }
        />
        <Route
          path="/productList/:list"
          element={
            <MobileContainer>
              <ProductList />
              <BalloonDecorationPage />
              <FloatingContact />
            </MobileContainer>
          }
        />
        <Route
          path="/cart"
          element={
            <MobileContainer>
              <ShoppingBag />
            </MobileContainer>
          }
        />
        <Route
          path="/payment"
          element={
            <MobileContainer>
              <Paymentpage />
            </MobileContainer>
          }
        />
        <Route
          path="/orderplaced/:orderId"
          element={
            <MobileContainer>
              <OrderPlaced />
            </MobileContainer>
          }
        />
        <Route
          path="/address-list"
          element={
            <MobileContainer>
              <AddAddress />
            </MobileContainer>
          }
        />
        <Route
          path="/add-address"
          element={
            <MobileContainer>
              <AddNewAddress />
            </MobileContainer>
          }
        />
        <Route
          path="/add-address/:id"
          element={
            <MobileContainer>
              <AddNewAddress />
            </MobileContainer>
          }
        />
        <Route
          path="/myOrders"
          element={
            <div className="min-h-screen flex justify-center bg-black">
              <div className="w-full max-w-screen-sm bg-white">
                <Order />
                <FloatingContact />
              </div>
            </div>
          }
        />
        <Route
          path="/contact"
          element={
            <div className="min-h-screen flex justify-center bg-black">
              <div className="w-full max-w-screen-sm bg-white">
                <ContactUs />
                <FloatingContact />
              </div>
            </div>
          }
        />
        <Route
          path="/faq"
          element={
            <MobileContainer>
              <FaqPage />
            </MobileContainer>
          }
        />
        <Route
          path="/TermsandCondition"
          element={
            <MobileContainer>
              <TermsAndConditionsPage />
            </MobileContainer>
          }
        />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin/home" element={<AdminHome />} />
        <Route path="/admin/leads" element={<AdminLeads />} />
        <Route path="/admin/products" element={<AdminProducts />} />
        <Route path="/admin/categories" element={<AdminCategories />} />
        <Route path="/admin/orders" element={<Orders />} />
        <Route path="/admin/slider" element={<AdminSliderPage />} />
        <Route path="/admin/zipcode" element={<ZipcodePage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
