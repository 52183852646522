import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

const GridComponent = ({ category, filterCriteria }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const db = getFirestore();
        const productsCollection = collection(db, 'products');
        let q = category !== 'all'
          ? query(productsCollection, where('category', '==', category))
          : productsCollection;

        const querySnapshot = await getDocs(q);
        const productData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          key: doc.id,
        }));

        const formattedProducts = productData.map((p) => ({
          key: p.key,
          imageUrl: p.downloadUrls[0],
          name: p.name,
          originalPrice: Number(p.originalPrice),
          price: Number(p.price),
          info: p.description[0],
        }));

        setProducts(formattedProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [category]);

  useEffect(() => {
    if (filterCriteria === 'low_to_high') {
      setProducts((prevProducts) =>
        [...prevProducts].sort((a, b) => a.price - b.price)
      );
    } else if (filterCriteria === 'high_to_low') {
      setProducts((prevProducts) =>
        [...prevProducts].sort((a, b) => b.price - a.price)
      );
    }
  }, [filterCriteria]);

  return (
    <div className="grid grid-cols-2 ">
      {products.map((item) => (
        <Link
          to={`/product/${item.key}`}
          key={item.key}
          className="relative bg-blue-300"
        >
          <img
            src={item.imageUrl}
            alt={item.name}
            className="w-full h-auto border-r border-t border-white object-cover object-center"
            style={{ height: '255px' }}
          />
          {/* Offer tag */}
          <div className="absolute top-1 right-1 bg-green-600 text-white px-2 py-1 rounded-md text-xs ">
            Pay <span>₹0</span>  Now
          </div>
          <div className="py-2 pb-5 bg-white border-r border-gray-200">
            <p className="px-2 text-sm truncate font-semibold">{item.name}</p>
            <p className="px-2 text-xs truncate text-gray-400">{item.info}</p>
            <div className="px-2 flex">
              <p className="text-xs pr-1 font-semibold">₹{item.price.toLocaleString()}</p>
              <p className="text-xs pr-1 text-gray-400 line-through">₹{item.originalPrice.toLocaleString()}</p>
              <p className="text-xs text-orange-400 font-semibold">
                ({(((item.originalPrice - item.price) / item.originalPrice) * 100).toFixed(2)}%)
              </p>
            </div>
          </div>
        </Link>
      ))}
      {loading && <p>Loading...</p>}
    </div>
  );
};

export default GridComponent;