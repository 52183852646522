import React, { useState } from 'react';
import Header from './Components/Header';
import { useNavigate } from 'react-router-dom';
import { addDoc, collection,getFirestore } from 'firebase/firestore';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const firestore = getFirestore(); // Get Firestore instance

    try {
      const docRef = await addDoc(collection(firestore, 'contactMessages'), {
        name,
        email,
        message,
        timestamp: new Date(), // Adding a timestamp
      });

      console.log('Document written with ID: ', docRef.id);
      alert('Form submitted successfully!');
      navigate('/');
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-gray-200">
      <Header />

      <div className="bg-white p-4">
        <h1 className="text-2xl font-semibold mb-4">Contact Us</h1>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-600">
              Your Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="mt-1 p-2 border border-gray-300 w-full"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-600">
              Your Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="mt-1 p-2 border border-gray-300 w-full"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="message" className="block text-sm font-medium text-gray-600">
              Your Message
            </label>
            <textarea
              id="message"
              name="message"
              rows="4"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              className="mt-1 p-2 border border-gray-300 w-full"
            ></textarea>
          </div>

          <button
            type="submit"
            className={`bg-black text-white p-2 rounded ${
              isSubmitting ? 'cursor-not-allowed' : 'cursor-pointer'
            }`}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
