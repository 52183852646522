import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const FilterSort = ({ onFilter }) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleFilterByPriceLow = () => {
    onFilter('low_to_high');
    handleClose();
  };

  const handleFilterByPriceHigh = () => {
    onFilter('high_to_low');
    handleClose();
  };
  
  if(!isOpen){
    return (

      <div className="z-60 border-t bg-white py-3 bottom-0 sticky text-black p-2 flex justify-between shadow-2xl cursor-pointer" onClick={togglePanel}>
        <div className="w-full border-r flex justify-center items-center">Filter</div>
      </div>
     
    );
  }else{
    return (
      <div>
        <div
          className={`fixed z-50 inset-0 bg-gray-800 opacity-50 ${isOpen ? 'block' : 'hidden'}`}
          onClick={handleClose}
        ></div>
        <div
          className={`fixed container mx-auto w-full max-w-screen-sm  z-50 bottom-0 transform transition-transform duration-300 ${
            isOpen ? "translate-y-0" : "translate-y-full"
          } ease-in-out bg-white p-4`}
        >
          <div className="mb-4">
            <div className="py-3 font-semibold text-sm">Filter By Price</div>
            <div className="flex flex-col space-y-2">
              <button onClick={handleFilterByPriceLow} className="bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded">
                Filter by Price Low to High
              </button>
              <button onClick={handleFilterByPriceHigh} className="bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded">
                Filter by Price High to Low
              </button>
            </div>
          </div>
        </div>
  
       
      </div>
    );
  }
  
};

export default FilterSort;
