import { useEffect } from "react";
import { getApp } from "firebase/app"; // Adjust the import if using Firebase modular SDK

const useFirebaseCleanup = () => {
  useEffect(() => {
    const unloadCallback = async () => {
      try {
        const app = getApp(); // Correctly call getApp() to retrieve the Firebase app instance
        await app.delete();
        console.log("Firebase app deleted successfully");
      } catch (error) {
        console.error("Error deleting Firebase app:", error);
      }
    };

    window.addEventListener("beforeunload", unloadCallback);

    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);
};

export default useFirebaseCleanup;
