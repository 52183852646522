import React, { useState } from 'react';
import { FaPhone, FaWhatsapp, FaUser, FaHeadset, FaTimes } from 'react-icons/fa';

const FloatingContact = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {/* Floating Main Button */}
      <button
        onClick={toggleMenu}
        className="bg-green-500 text-white p-4 rounded-full shadow-lg focus:outline-none hover:bg-green-600 transition"
      >
        {isOpen ? <FaTimes size={24} /> : <FaHeadset size={24} />}
      </button>

      {/* Options: Call and WhatsApp */}
      {isOpen && (
        <div className="flex flex-col items-end space-y-2 mt-2">
          <a
            href="tel:+917019337663"
            className="flex items-center bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-600 transition"
            title="Call Us"
          >
            <FaPhone size={20} />
          </a>
          <a
            href="https://wa.me/917019337663"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 transition"
            title="WhatsApp Us"
          >
            <FaWhatsapp size={20} />
          </a>
        </div>
      )}
    </div>
  );
};

export default FloatingContact;
