import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import CategoryScroll from './Components/CatComp';
import GridComponent from './Components/Grid';
import SidePanel from './Components/Side';
import AuthSlide from './Components/BottomSlide';
import { useState } from 'react';
import BalloonDecorationPage from './Components/BalloonDecorationPage';
import FloatingContact from './Components/FloatingContact';
import { useEffect } from 'react';
import UserDetailsModal from './Components/UserDetailsModal';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import axios from "axios";


function App() {

  const [userIP, setUserIP] = useState('');
  const [showModal, setShowModal] = useState(false);

  const db = getFirestore();


  const fetchUserIP = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      setUserIP(response.data.ip);
    } catch (error) {
      console.error("Error fetching IP:", error);
    }
  };

  // Check if the modal should be shown based on the IP and date
  
  const checkIfModalShouldShow = async (ip) => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const datePath = `${year}_${month}_${day}`;

    const dateDocRef = doc(db, "userDetails", datePath);
    const ipDocRef = doc(dateDocRef,'details',ip);

    const docSnap = await getDoc(ipDocRef);

    const dataP = docSnap.data();
    console.log(dataP);

    if (!dataP) {
      setShowModal(true);
      
    }
  }; 

  useEffect(() => {
    fetchUserIP().then(() => {
      if (userIP) {
        
        checkIfModalShouldShow(userIP);
      }
    });
  }, [userIP]); 





  return (
   <>
   <Header></Header>
   <CategoryScroll></CategoryScroll>
   <GridComponent category={"all"}/>
   {showModal &&  <UserDetailsModal userIP={userIP} setShowModal={setShowModal} />}

   <FloatingContact/>

   <BalloonDecorationPage/>

   
   
   </>
  );
}

export default App;
