import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from "../firebase/firebaseConfig";
import { Link } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";


const Order = () => {
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [user, loading] = useAuthState(auth);
    const [isMobileOpen, setIsMobileOpen] = useState(false);

    const firestore = getFirestore(); // Get Firestore instance

    // Add states for month and year
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month

    const months = [
        { value: 1, label: "January" },
        { value: 2, label: "February" },
        { value: 3, label: "March" },
        { value: 4, label: "April" },
        { value: 5, label: "May" },
        { value: 6, label: "June" },
        { value: 7, label: "July" },
        { value: 8, label: "August" },
        { value: 9, label: "September" },
        { value: 10, label: "October" },
        { value: 11, label: "November" },
        { value: 12, label: "December" },
    ];

    const cancelOrder = async (orderId, monthYear) => {
        try {
            // Reference to the specific order document
            const orderDocRef = doc(firestore, 'orders', monthYear, 'order', orderId);

            // Update the order status to 'Canceled'
            await updateDoc(orderDocRef, {
                orderStatus: 'Canceled',
            });

            // Optimistically update the UI
            setOrders(prevOrders =>
                prevOrders.map(order =>
                    order.id === orderId ? { ...order, orderStatus: 'Canceled' } : order
                )
            );
        } catch (error) {
            console.error("Error canceling order:", error.message);
        }
    }
    
    useEffect(() => {
        // Fetch order details using selected month and year
        const fetchOrderDetails = async () => {
            try {
                if (user) {
                    const year = selectedYear;
                    const month = selectedMonth.toString().padStart(2, '0'); // Pad month with 0
                    const monthYear = `${year}${month}`; // e.g., '202408'

                    // Reference to the user's orders collection
                    const ordersCollectionRef = collection(firestore, 'orders', monthYear, 'order');

                    // Query orders based on user ID
                    const q = query(
                        ordersCollectionRef, 
                        where('userId', '==', user.uid),
                        where('isPlaced', '==', true)  // Added filter for isPlaced being true
                      );

                    // Get documents from Firestore
                    const querySnapshot = await getDocs(q);

                    // Process documents
                    const ordersData = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));

                    setOrders(ordersData);
                }
            } catch (error) {
                console.error("Error fetching order details:", error.message);
            }
        };

        fetchOrderDetails();
    }, [user, firestore, selectedMonth, selectedYear]);

    // Handlers for month and year change
    const handleMonthChange = (e) => {
        setSelectedMonth(Number(e.target.value));
    };

    const handleYearChange = (e) => {
        setSelectedYear(Number(e.target.value));
    };

    return (
        <>
            <nav className="sticky top-0 z-40 border-gray-600 bg-white shadow">
                <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-2">
                    <div className="flex items-center md:w-auto w-full">
                        <div className="flex items-center">
                            <button
                                onClick={() => navigate(-1)}
                                className="focus:outline-none cursor-pointer"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 h-6 text-black"
                                    viewBox="0 0 21 22"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M.27 9.992a1.289 1.289 0 0 0-.266.42L0 10.424l.004-.01c.061-.156.151-.3.267-.421Zm10.82-7.578-7.534 7.51h16.032a.989.989 0 0 1 0 1.977H3.555l7.536 7.512a.989.989 0 0 1 0 1.404l-.004.004h-.001a.988.988 0 0 1-1.404 0L.486 11.625a.988.988 0 0 1-.202-.32l-.007-.016a.988.988 0 0 1 0-.75l.007-.017a.989.989 0 0 1 .202-.32L9.68 1.006a.996.996 0 1 1 1.408 1.408Z"
                                    ></path>
                                </svg>
                            </button>
                        </div>

                        <Link to="/myOrders" className="ml-4 flex font-bold items-center">
                            My Orders
                        </Link>
                    </div>
                </div>
            </nav>

            <div className="border-t bg-gray-100 h-auto">
                <div className="flex mt-2 bg-white">
                    <div className="container mx-auto">
                        {/* Month and Year Selection */}
                        <div className="flex items-center space-x-2 my-4">
                            <span className="p-2">Event On:</span>
                            <select
                                value={selectedMonth}
                                onChange={handleMonthChange}
                                className="border border-gray-300 p-2 rounded"
                            >
                                {months.map((month) => (
                                    <option key={month.value} value={month.value}>
                                        {month.label}
                                    </option>
                                ))}
                            </select>

                            <input
                                type="number"
                                value={selectedYear}
                                onChange={handleYearChange}
                                className="border border-gray-300 p-2 rounded"
                                min="2000"
                                max={new Date().getFullYear()}
                            />
                        </div>

                        {orders.length > 0 ? (
                            orders.map(order => (
                                <div key={order.id} className="flex mt-3 bg-white border">
                                    <div className="w-44 h-40 p-2 overflow-hidden">
                                        <img
                                            className="w-full h-full object-cover"
                                            src={order.imageUrl}
                                            alt="Order Image"
                                        />
                                    </div>

                                    <div className="text-xs mt-2">
                                        <p className="mt-2 font-semibold">{order.orderNumber}</p>
                                        <div className="flex mt-2">
                                            Order Total :
                                            <p className="font-bold"> ₹{order.total}</p>
                                        </div>

                                        <div className="flex mt-2">
                                            Payment Method :
                                            <p className="font-bold"> {order.paymentMethod}</p>  
                                        </div>

                                        <div className="flex mt-2">
                                            Order Status : 
                                            <p className="font-bold"> {order.orderStatus}</p>
                                        </div>

                                        <div className="flex mt-2 space-x-1">
                                            <p>{convertTimestampToDate(order.dateTime)}</p>
                                        </div>

                                        {isFutureDate(order.dateTime) & order.orderStatus != 'Canceled' && (
    <div className="flex mt-2 space-x-2 items-center">
        {/* Cancellation Info */}
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.63639 6.99013C6.84386 7.1976 6.84386 7.53397 6.63639 7.74143L5.7725 8.60533H8.27232C9.21251 8.60533 9.97949 7.84333 9.97949 6.89824C9.97949 5.95914 9.21859 5.19824 8.27949 5.19824H6.89116C6.59776 5.19824 6.35991 4.96039 6.35991 4.66699C6.35991 4.37359 6.59776 4.13574 6.89116 4.13574H8.27949C9.80539 4.13574 11.042 5.37234 11.042 6.89824C11.042 8.43232 9.79722 9.66783 8.27241 9.66783H5.77242L6.63639 10.5318C6.84386 10.7393 6.84386 11.0756 6.63639 11.2831C6.42893 11.4906 6.09256 11.4906 5.88509 11.2831L4.11426 9.51227C4.0417 9.43971 3.99452 9.35138 3.97271 9.25831C3.96352 9.21922 3.95866 9.17846 3.95866 9.13658C3.95866 9.05996 3.97488 8.98713 4.00407 8.92134C4.02519 8.87367 4.05366 8.82847 4.08949 8.78745C4.09828 8.77738 4.10745 8.76764 4.11697 8.75826L5.88509 6.99013C6.09256 6.78267 6.42893 6.78267 6.63639 6.99013Z" fill="#282C3F"></path>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.416992 7.50033C0.416992 3.58831 3.58831 0.416992 7.50033 0.416992C11.4123 0.416992 14.5837 3.58831 14.5837 7.50033C14.5837 11.4123 11.4123 14.5837 7.50033 14.5837C3.58831 14.5837 0.416992 11.4123 0.416992 7.50033ZM7.50033 1.47949C4.17511 1.47949 1.47949 4.17511 1.47949 7.50033C1.47949 10.8255 4.17511 13.5212 7.50033 13.5212C10.8255 13.5212 13.5212 10.8255 13.5212 7.50033C13.5212 4.17511 10.8255 1.47949 7.50033 1.47949Z" fill="#282C3F"></path>
        </svg>
        <p>Free Cancellation Available</p>

        {/* Phone Icon with Link */}
        <a
            href="tel:+917019337663"
            className="flex items-center text-blue-600 underline hover:no-underline"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
                width="15"
                height="15"
                className="mr-1"
            >
                <path d="M21 16.5a3.8 3.8 0 0 1-1.5 3c-1.7 1.3-3.7 2-5.5 1.5-1.8-.6-4.1-2.5-6.4-5C5.1 12.7 3.2 10.4 2.6 8.6c-.5-1.8.1-3.8 1.5-5.5.8-.8 2.3-.8 3.2 0l2 2c.6.6.8 1.3.5 2-.3.7-.9 1.7-1.6 2.5-.2.3-.1.7 0 1 .9 1.6 2.4 3.2 4 4.1.4.3.7.3 1 0 .8-.7 1.8-1.3 2.5-1.6.7-.3 1.4-.1 2 .5l2 2c.7.7.7 2.3 0 3.2z" />
            </svg>
            Call +917019337663
        </a>
    </div>
)}

                                       
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-center mt-4">No orders found for the selected month.</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

const isFutureDate = (orderDate) => {
    const currentDate = new Date();
    return orderDate.seconds * 1000 > currentDate.getTime();
};


function convertTimestampToDate(timestamp) {
    // Convert seconds and nanoseconds to milliseconds
    const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6;

    // Create a new Date object using the milliseconds
    const date = new Date(milliseconds);

    // Extract date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    let hours = date.getHours(); // 24-hour format hours
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Convert to 12-hour format
    const amPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert "0" hours to "12"

    // Format the date as "YYYY-MM-DD HH:mm:ss AM/PM"
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${amPm}`;

    return formattedDate;
}


export default Order;
